import { IonCol, IonContent, IonHeader, IonPage, IonTitle,IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Home.css';

const Home: React.FC = () => {
  return (
      <IonPage>  
        <IonContent fullscreen={false} className="ion-padding">
            <ExploreContainer />
        </IonContent>
      </IonPage>
  );
};

export default Home;
