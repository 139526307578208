import { useEffect, useState, useSyncExternalStore } from "react";
import { Drivers, Storage } from '@ionic/storage';
import { todayOutline } from "ionicons/icons";
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

const RECIPES_KEY = 'my-recipes'

export interface RecipeItem {
    recipen: string;
    reciped: string;
    recipem: string;
    created: number;
    dateCounter: number;
    status: number;
    vecesComido: number;
    fechasComido: string;
    id: string;

}

export function useStorage() {
    const [store, setStore] = useState<Storage>();
    const [recipes, setRecipes] = useState<RecipeItem[]>([]);
    useEffect(()=>{
        const initStorage = async () => {
            const newStore = new Storage({
                name: 'recipesdb',
                driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
            });
            await newStore.defineDriver(CordovaSQLiteDriver);
            const store = await newStore.create();
            setStore(store);

            const storedRecipes = await store.get(RECIPES_KEY) || [];
            setRecipes(storedRecipes);
        }
        initStorage();
    }, []);

    const addRecipe = async (recipen: string, reciped: string, recipem: string) => {
        const newRecipe = {
            recipen,
            reciped,
            recipem,
            created: new Date().getTime(),
            dateCounter: new Date().getTime(),
            status: 0,
            vecesComido: 0,
            fechasComido: 'Nunca',
            id: ''+new Date().getTime()
        }
        const updatedRecipes =  [...recipes, newRecipe]
        setRecipes(updatedRecipes)
        store?.set(RECIPES_KEY, updatedRecipes)
    }

    const updateRecipeDate = async(id: string) =>{
        const toUpdate = [...recipes];
        let recipe = toUpdate.filter(recipe => recipe.id===id)[0];
        recipe.dateCounter = new Date().getTime();
        setRecipes(toUpdate);
        return store?.set(RECIPES_KEY,toUpdate);
    }

    const updateRecipeAll = async(id: string, recipen: string, reciped: string, recipem: string) =>{
        const toUpdate = [...recipes];
        let recipe = toUpdate.filter(recipe => recipe.id===id)[0];
        recipe.recipen = recipen;
        recipe.reciped = reciped;
        recipe.recipem = recipem;
        setRecipes(toUpdate);
        return store?.set(RECIPES_KEY,toUpdate);
    }

    const updateVeces = async(id: string) =>{
        const toUpdate = [...recipes];
        let recipe = toUpdate.filter(recipe => recipe.id===id)[0];
        recipe.vecesComido++;
        setRecipes(toUpdate);
        return store?.set(RECIPES_KEY,toUpdate);
    }

    const updateFechasComido = async(id: string) =>{
        const toUpdate = [...recipes];
        let recipe = toUpdate.filter(recipe => recipe.id===id)[0];
        recipe.fechasComido = new Date().toLocaleString();
        setRecipes(toUpdate);
        return store?.set(RECIPES_KEY,toUpdate);
    }

    const updateRecipeStatus = async(id: string, status:number) =>{
        const toUpdate = [...recipes];
        let recipe = toUpdate.filter(recipe => recipe.id===id)[0];
        recipe.status = status;
        setRecipes(toUpdate);
        return store?.set(RECIPES_KEY,toUpdate);
    }

    const removeRecipe = async(id: string) =>{
        const toUpdate = recipes.filter(recipe => recipe.id !== id);
        setRecipes(toUpdate);
        return store?.set(RECIPES_KEY,toUpdate);
    }

    const getRecipeN = async(id: string) =>{
        const toRecipe = recipes.filter(recipe => recipe.id === id);
        return toRecipe;
    }
    const getRecipeD = async(id: string) =>{
        const toRecipe = recipes.filter(recipe => recipe.id === id);
        return toRecipe;
    }

    return {
        recipes,
        addRecipe,
        updateRecipeStatus,
        removeRecipe,
        getRecipeN,
        updateRecipeDate,
        updateVeces,
        updateFechasComido,
        updateRecipeAll
    }


}