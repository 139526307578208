import './ExploreContainer.css';
import React from 'react';
import { IonActionSheet, IonCard, IonRow, IonCol, IonImg, IonFab, IonFabButton, useIonToast, useIonAlert, IonTextarea, IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonList, IonModal, IonTitle, IonToolbar, IonFooter, IonItemOption, IonItemOptions, IonItemSliding, IonIcon, IonGrid, IonCardHeader, IonCardTitle, IonCardContent } from '@ionic/react';
import { useStorage } from '../hooks/useStorage';
import { useState, useRef, useEffect } from 'react';
import { OverlayEventDetail } from '@ionic/core/components';
import { returnDownBackOutline,trash,clipboardOutline, trashBin, pizza, stopwatch, pencil, images, square, triangle, camera, close, image } from 'ionicons/icons';
import { key } from 'localforage-cordovasqlitedriver';
import { usePhotoGallery, UserPhoto } from '../hooks/usePhotoGallery';
import { Clipboard } from '@capacitor/clipboard';
import { time } from 'console';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Photo } from '@capacitor/camera';


interface ContainerProps { }

const ExploreContainer: React.FC<ContainerProps> = () => {
  const { recipes, addRecipe, removeRecipe, getRecipeN, updateRecipeDate, updateVeces, updateFechasComido, updateRecipeAll} = useStorage();
  const [recipe, setRecipe] = useState('');
  const [recipei, setRecipei] = useState('');
  const [reciped, setReciped] = useState('');
  const [recipen, setRecipen] = useState('');
  const [recipem, setRecipem] = useState('');
  const [vecesComido, setVeces] = useState(Number);
  const [fechasComido, setFechasComido] = useState('');
  const ionList = useRef(null as any);
  const [showModal, setShowModal] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [counter, setCounter] = React.useState(100);
  const [present] = useIonToast();

  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  const input2 = useRef<HTMLIonInputElement>(null);
  const input3 = useRef<HTMLIonInputElement>(null);
  const modal2 = useRef<HTMLIonModalElement>(null);
  const modal3 = useRef<HTMLIonModalElement>(null);
  const modal4 = useRef<HTMLIonModalElement>(null);

  const { deletePhoto, photos, takePhoto } = usePhotoGallery();
  const [photoToDelete, setPhotoToDelete] = useState<UserPhoto>();

  const createRecipe = async () => {
    
    setFechasComido(new Date().toLocaleDateString());
    await addRecipe(recipe,reciped,recipem);
    setRecipe('');
    setReciped('');
    setRecipem('');
    
    confirm();
  }

  const deleteRecipe = async (id: string) => {
    await removeRecipe(id);
    ionList.current.closeSlidingItems();
  }

  const updateDates = async (id: string) => {
    await updateVeces(id);
    await updateRecipeDate(id);
    await updateFechasComido(id);
    ionList.current.closeSlidingItems();

  }

  const [message, setMessage] = useState(
    'This modal example uses triggers to automatically open a modal when the button is clicked.'
  );

  function confirm() {
    modal.current?.dismiss(input.current?.value, 'confirm');

  }

  function confirm2() {
    modal3.current?.dismiss(input.current?.value, 'confirm');

  }

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    setShowModal4(false);
    if (ev.detail.role === 'confirm') {
      setMessage(`Hello, ${ev.detail.data}!`);
    }
  }

  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter + 1), 1000);
  }, [counter]);

  function cleanRecipeData () {
    setRecipe('');
    setReciped('');
    setRecipen('');
    setRecipem('');

  }

  function getRecipeData (id: string,recipen: string, reciped: string, recipem: string, vecesComido: number, fechasComido: string) {
    setShowModal(true);
    setRecipe(id);
    setReciped(reciped);
    setRecipen(recipen);
    setRecipem(recipem);
    setVeces(vecesComido);
    setFechasComido(fechasComido);
    setRecipei(id);

  }

  const writeToClipboard = async () => {
    await Clipboard.write({
      string: recipen+"\n"+"Ingredientes: "+reciped+"\n"+"Método de preparación: "+recipem
    });
    presentToast('bottom');
    
  };

  const presentToast = (position: 'top' | 'middle' | 'bottom') => {
    present({
      message: 'Copiado',
      duration: 1400,
      position: position
    });
  };
 
  const checkClipboard = async () => {
    const { type, value } = await Clipboard.read();
    console.log(`Got ${type} from clipboard: ${value}`);
  };

  function editRecipe () {

    setShowModal3(true);
    
  }

  function verFoto (photo: UserPhoto) {
    setPhotoToDelete(photo);
    setShowModal4(true);
    
  }

  function borrarFoto () {
    
    setShowModal4(true);
    
  }

  const saveERecipe = async (id: string, recipen: string, reciped: string, recipem: string) =>  {

    await updateRecipeAll(id,recipen,reciped,recipem);
    setShowModal(false);
    setShowModal3(false);

  }

  return (
    <div className="container">
      <br></br>
      <IonLabel className="titulo">Recetas</IonLabel>
      <br></br>
      <br></br>
      <br></br>
      <IonButton id="open-modal" expand="block" 
        onClick={() => cleanRecipeData()}>Nueva receta  <IonIcon icon={pizza}></IonIcon>
      </IonButton>
      <br></br>
      <IonList ref={ionList} lines="full">
        { recipes.map((recipe, key) => (
          <IonItemSliding key={key} className="item">
          <IonItemOptions side="start">
            <IonItemOption color="warning" onClick={() => updateDates(recipe.id)}>
              <IonIcon icon={stopwatch}></IonIcon> = 0
            </IonItemOption>
          </IonItemOptions>
            <IonItem onClick={() => getRecipeData(recipe.id,recipe.recipen,recipe.reciped,recipe.recipem,recipe.vecesComido,recipe.fechasComido)}  key={recipe.id}>
                <span className="ion-text-left">
                  {recipe.recipen}
                </span>
                <span className="ion-text-right">
                  Hace { (((((new Date(((new Date().getTime()) - recipe.dateCounter)).getTime() )/1000)/60)/60)/24).toFixed(1)} días
                  {/* (((((new Date(((new Date().getTime()) - recipe.dateCounter)).getTime() )/1000)/60)/60)%24).toFixed(0)} hrs
                  { (((((new Date(((new Date().getTime()) - recipe.dateCounter)).getTime() )/1000)/60)%60)%24).toFixed(0)} mins*/}
                </span>
            </IonItem>
            <IonItemOptions side="end">
            <IonItemOption color="danger" onClick={() => deleteRecipe(recipe.id)}> <IonIcon icon={trashBin}> </IonIcon ></IonItemOption>
          </IonItemOptions>
          </IonItemSliding>
        ))}
      </IonList>
        <IonModal ref={modal} trigger="open-modal" onWillDismiss={(ev) => onWillDismiss(ev)}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => modal.current?.dismiss()}>Cancelar</IonButton>
              </IonButtons>
              <IonTitle>Nueva receta</IonTitle>
              <IonButtons slot="end">
                <IonButton strong={true} onClick= {() => createRecipe()}>
                  Confirmar
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonItem  lines="none">
              <IonLabel position="stacked" class='enterRecetaTexto'><h2>Escribe tu receta: ...<IonIcon icon={pencil}></IonIcon></h2></IonLabel>
              <br></br>
              <IonInput  value = {recipe} onIonChange={(e)=>setRecipe(e.detail.value!) } placeholder="Título: (Lentajas con...)" />
              <br></br>
              <br></br>
              <IonTextarea autoGrow={true} value = {reciped} onIonChange={(o)=>setReciped(o.detail.value!) } placeholder="Ingredientes:" />
              <br></br>
              <IonTextarea autoGrow={true} value = {recipem} onIonChange={(u)=>setRecipem(u.detail.value!) } placeholder="Método de preparación:" />
            </IonItem>
          </IonContent>
        </IonModal>

      <IonModal ref={modal2} isOpen={showModal} onWillDismiss={(ev2) => onWillDismiss(ev2)}>
        <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => setShowModal(false)}>Cerrar</IonButton>
              </IonButtons>
              <IonTitle>{recipen}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => editRecipe()}>Editar</IonButton>
              </IonButtons>
            </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <p><IonLabel className='b'>Ingredientes: </IonLabel></p>
          <p>{reciped}</p>
          <br></br>
          <p><IonLabel className='b'>Método de preparación: </IonLabel></p>
          <p>{recipem}</p>
          <br></br>
          <p><IonLabel className='b'>Veces que lo has preparado:</IonLabel> {vecesComido}</p>
          <p><IonLabel className='c'>Última vez: </IonLabel><IonLabel className='c'>{fechasComido}</IonLabel></p>
          <br></br>

          <IonRow className='fotobutton'>      
          <IonCol><IonButton onClick={() => writeToClipboard()}>Copiar <IonIcon icon={clipboardOutline}></IonIcon></IonButton>
          <IonButton onClick={() => takePhoto(recipei)}>Foto  <IonIcon icon={camera}> </IonIcon></IonButton></IonCol>
          </IonRow>

          <IonModal id="foto-modal" ref={modal4} isOpen={showModal4} onWillDismiss={(ev4) => onWillDismiss(ev4)}>
            <IonCard>
              <IonImg  src={photoToDelete?.webviewPath} />
            </IonCard>
            <IonRow className='fotobutton'>
              <IonCol><IonButton onClick={() => setShowModal4(false)}>Cerrar <IonIcon icon={returnDownBackOutline}></IonIcon></IonButton>
              <IonButton onClick={() => {if (photoToDelete) {
                deletePhoto(photoToDelete);
                setPhotoToDelete(undefined);
                setShowModal4(false);
              }}}>Borrar <IonIcon icon={trashBin}></IonIcon></IonButton></IonCol>
            </IonRow>
          </IonModal>

          <IonRow>
            {photos.filter(photo => photo.recipeid.includes(recipei)).map((photo, index) => (
              <IonCol size="6" key={index}>
                <IonCard>
                  <IonImg onClick={() => verFoto(photo)} src={photo.webviewPath} />
                </IonCard>
              </IonCol>
            ))}
          </IonRow>
        </IonContent>
      </IonModal>

      <IonModal ref={modal3} isOpen={showModal3} onWillDismiss={(ev3) => onWillDismiss(ev3)}>
        <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => setShowModal3(false)}>Cerrar</IonButton>
              </IonButtons>
              <IonTitle>{recipen} <IonIcon icon={pencil}></IonIcon></IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => saveERecipe(recipei,recipen,reciped,recipem)}>Guardar</IonButton>
              </IonButtons>
            </IonToolbar>
        </IonHeader>
          <IonContent className="ion-padding">
            <p><IonLabel className='b'>Título: </IonLabel>
            <IonInput  value = {recipen} onIonChange={(e)=>setRecipen(e.detail.value!) } placeholder="Título:" />
            </p>
            <br></br>
            <p><IonLabel className='b'>Ingredientes: </IonLabel>
            <IonTextarea autoGrow={true} value = {reciped} onIonChange={(o)=>setReciped(o.detail.value!) } placeholder="Ingredientes:" />
            </p>
            <br></br>
            <p><IonLabel className='b'>Método de preparación: </IonLabel>
            <IonTextarea autoGrow={true} value = {recipem} onIonChange={(u)=>setRecipem(u.detail.value!) } placeholder="Método de preparación:" />
            </p>
          </IonContent>
        </IonModal>


    </div>
  );
};

export default ExploreContainer;
